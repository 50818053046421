<template>
  <div>
    <MarketingNavBar />
    <main class="min-h-screen">
      <slot />
    </main>
    <MarketingFooter />
  </div>
</template>
<script setup lang="ts"></script>
